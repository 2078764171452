import { HomeDescriptionSectionProps } from "../../model";

export default function DescriptionSection({ contone, conttwo, contthree }: HomeDescriptionSectionProps) {
	return (
		<section id="home-description-section" class="flex flex-col bg#paper items-center">
			<div class="w-845px mx-auto text-31px font-bold leading-20px text#p py-77px">
				<p class="py-20px">{contone} </p>
				<p  class="py-20px">{conttwo} </p>
				<p  class="py-20px">{contthree} </p>
			</div>
			<div>
				<hr class="max-w-screen w-screen h-2px bg#p" />
			</div>
		</section>
	);
}
